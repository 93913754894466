import React, { useEffect, useState } from 'react';
import Popup from './Popup';

import styles from './footer.module.css';
import logo from '../../assets/logo.png';
import centerline from '../../assets/FooterVert.png';
import icon1 from '../../assets/phone.png';
import icon2 from '../../assets/txt.png';
import icon3 from '../../assets/msg.png';
import footerline from '../../assets/boderline.png';

function Footer() {

    const [showPopup, setShowPopup] = useState(false);

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
       const timeout = setTimeout(() => {
            setShowPopup(!showPopup)
        }, 45000);

        return () => clearTimeout(timeout);
    }, []);

    return (
       <>   
        <div className={styles.footer}>
            <img className={styles.border1} src={footerline} alt='wait'/>
            <div className={styles.mainContainer}>
                <img className={styles.logo} src={logo} alt='wait' onClick={() => window.scrollTo(0, 0)} />
                <img className={styles.centerLine} src={centerline} alt='wait' />
                <div>
                    <p className={styles.call}><a href='tel:8445703201'><img src={icon1} alt='call'/> 844.570.3201</a> <span>(call)</span></p>
                    <p className={styles.text}><a href='sms:6507596343'><img src={icon2} alt='text'/> 650.759.6343</a> <span>(text)</span></p>
                    <p className={styles.email}><a href='mailto:social@tb2.health'><img src={icon3} alt='email'/> social@tb2.health</a></p>
                </div>
                <img className={styles.border2} src={footerline} alt='wait'/>
            </div>
            <div className={styles.linkContainer}>
                <div className={styles.footerLink}>
                <span>.</span>
                <p>
                    <a href='/programs'>Programs</a>
                </p>
                </div>
                <div className={styles.footerLink}>
                <span>.</span><p>
                    <a href='/about-us'>About Us</a>
                    </p>
                </div>
                <div className={styles.footerLink}>
                <span>.</span><p>
                   <a href='/contact'>Contact Us</a>
                    </p> 
                </div>
                <div className={styles.footerLink}>
                <span>.</span><p>
                     <a href='/knowledge-center'>Knowledge Center</a>
                    </p>
                </div>
                <div className={styles.footerLink}>
                <span>.</span> <p>
                    <a href='/blog'>Blog</a>
                    </p>
                </div>
                <div className={styles.footerLink}>
                <span>.</span> <p>
                    <a href='/articles'>Articles</a>
                    </p>
                </div>
                <div className={styles.footerLink}>
                <span>.</span><p>
                     <a href='/success-stories'>Success Stories</a>
                    </p> 
                </div>
            </div>
            <div className={styles.copyrightContainer}>
                <div className={styles.copyright}>
                    <div className={styles.copyrightText}>2024 Pillars for Health. All Rights Reserved</div>
                    <div className={styles.pipe}>|</div>
                    <div className={styles.footerLink}>
                        <a href='/terms'>Terms and Conditions</a>
                        <a href='/privacy'>Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>
        {showPopup && <Popup onClose={handleClosePopup}/>}
       </>  
    );
}

export default Footer;
