import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './components/.shared/reportWebVitals';

import './index.css';
import 'animate.css';

import Privacy from './components/Privacy/Privacy';
import Terms from './components/Terms/Terms';
import Programs from './components/Programs/Programs';
import Maintenance from './components/Programs/Maintenance';
import Blog from './components/Blog/Blog';
import Article from './components/Blog/Article';
import Success from './components/SuccessStories/Success';
import Contact from './components/ContactUs/Contact';
import Welcome from './components/Welcome/Welcome';
import Checkout from './components/Checkout/Checkout';
import Intake from './components/Intake/Intake';
import Articles from './components/Articles/Articles';
import Landing from './components/Landing/Landing';
import KnowledgeCenter from './components/KnowledgeCenter/KnowledgeCenter';
import AboutUs from './components/AboutUs/AboutUs';
import Login from './components/Login/Login';

const App = () => {
  
  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]; 
    localStorage.setItem('todaysDate', today);
  }, []);
 
  
  return (
    // <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="packages" element={<Programs />} />
          <Route path="products" element={<Programs />} />
          <Route path="programs" element={<Programs />} />
          <Route path="maintenance" element={<Maintenance />} />
          <Route path="blog" element={<Blog />} />
          <Route path="articles" element={<Articles />} />
          <Route path="articles/:id" element={<Article />} />
          <Route path="success-stories" element={<Success />} />
          <Route path="contact" element={<Contact />} />
          <Route path="welcome" element={<Welcome />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="intake" element={<Intake />} />
          <Route path="start" element={<Programs />} />
          <Route path="research" element={<KnowledgeCenter />} />
          <Route path="safety" element={<KnowledgeCenter />} />
          <Route path="faq" element={<KnowledgeCenter />} />
          <Route path="research-center" element={<KnowledgeCenter />} />
          <Route path="safety-center" element={<KnowledgeCenter />} />
          <Route path="knowledge-center" element={<KnowledgeCenter />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="landing" element={<Landing />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<Landing />} />
        </Routes>
      </BrowserRouter>
    // </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals(console.log);
