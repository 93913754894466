import React, { useEffect, useRef, useState } from 'react';

import styles from './PlansAsLowAs.module.css';

import circleright from "../../assets/circle-right.svg";
import horizontalRule from "../../assets/boderline.png"
import plans299 from "../../assets/plans299.png"

function PlansAsLowAs() {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    function handleGetStarted() {
        window.location.href = '/start';
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {
                threshold: 0.5,
            }
        );

        let currentElement = elementRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }
        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, []);

    return (
        <>
            <img className={styles.horizontalRule} src={horizontalRule} alt='' />
            <div ref={elementRef} className={styles.asLowAs}>
                <div className={styles.asLowAsText + (isVisible ? ' animate__animated animate__fadeInLeft' : '')}>Plans as low as</div>
                <img className={styles.asLowAsImg} src={plans299} alt='$299'/>
                <div className={styles.letsGoButton + (isVisible ? ' animate__animated animate__fadeInRight' : '')} onClick={handleGetStarted}>Let's Go! <img className={styles.buttonArrow} src={circleright} alt=''/></div>
            </div>
            <img className={styles.horizontalRule} src={horizontalRule} alt='' />

        </>
    );
}

export default PlansAsLowAs;
