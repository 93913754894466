import React, { useEffect, useRef, useState } from 'react';

import styles from './FreeToBe.module.css';
import arrow from "../../assets/JynLYS.png";
import tick from "../../assets/tick.png"
import FreeTo from '../../assets/FreeTo.png';

function FreeToBe() {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {
                threshold: 0.5,
            }
        );

        let currentElement = elementRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }
        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, []);
    
    function handleGetStarted() {      
        window.location.href = '/start';
    }
    
    return (
        <>
           
           
            <div className={styles.freeToBe}>
                <img ref={elementRef} className={styles.freeImage + (isVisible ? ' animate__animated animate__flipInX' : '')}  src={FreeTo} alt='wait' />
                <div ref={elementRef} className={styles.freeContainer}>
                    <div className={styles.tick}>
                        <img className={styles.tickImg} src={tick} alt='' />
                        <p className={styles.tickText}>Lose weight without stress</p>
                    </div>
                    <div className={styles.tick}>
                        <img className={styles.tickImg} src={tick} alt='' />
                        <p className={styles.tickText}>Stay motivated</p>
                    </div>
                    <div className={styles.tick}>
                        <img className={styles.tickImg} src={tick} alt='' />
                        <p className={styles.tickText}>Enjoy lasting results</p>
                    </div>
                    <p className={styles.letsGo + (isVisible ? ' animate__animated animate__rubberBand' : '')} onClick={handleGetStarted}>Let's Go! <img className={styles.arrowButton} src={arrow} alt='' /> </p>
                </div>
            </div>
            
        </>
    );
}

export default FreeToBe;
