import React from 'react';
import Header from '../.shared/header';
import Footer from '../.shared/footer';
import WomanHero from './WomanHero';
import Steps from './Steps';
import Support from './Support';
import FreeToBe from './FreeToBe';
import SuccessStories from './SuccessStories';
import PlansAsLowAs from './PlansAsLowAs';
import VialHero from './VialHero';
import StartMyJourney from './StartMyJourney';


const subdomain = window.location.host.split('.')[0];
localStorage.setItem('subdomain', subdomain);
    
const path = window.location.pathname.split('/')[1];
if (path === 'podcast') {
    localStorage.setItem('discount', path);
}

function Landing() {
   
    return (
        <>
            <Header />
            <VialHero />
            <Support />
            <Steps />
            <StartMyJourney />
            <SuccessStories />
            <Footer />
        </>
    );
}

export default Landing;
