import { useState, useRef, useEffect } from 'react';

import styles from './StartMyJourney.module.css';
import circleright from "../../assets/circle-right.svg";


function StartMyJourney() {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    const handleHover = (e) => {
        e.target.classList.add('animate__animated', 'animate__pulse', styles.glow);
    };
    const handleHoverEnd = (e) => {
        e.target.classList.remove('animate__animated', 'animate__pulse', styles.glow);
    };        
    const handleGetStarted = () => {
        window.location.href = '/start';
    }
    
    useEffect(() => {
        const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
            setIsVisible(true);
            }
        },
        {
            threshold: 0.5,
        }
        );
    
        let currentElement = elementRef.current;
        if (currentElement) {
        observer.observe(currentElement);
        }
        return () => {
        if (currentElement) {
            observer.unobserve(currentElement);
        }
        };
    }, []);

    
    return (
        <div className={styles.startMyJourney}>
            <p ref={elementRef} 
                className={styles.journeyButton}
                onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
                onClick={handleGetStarted}>Start My Weight Loss Journey<img className={styles.buttonArrow} src={circleright} alt='Start' /></p>
        </div>
    );
}

export default StartMyJourney;